import Link from '@/components/Link';
import ListItem from '@/components/ListItem';
import UnorderedList from '@/components/UnorderedList';

function MenuQuicklinksIsNew() {
  return (
    <div className="inline-flex rounded-full bg-yellow-100 px-2 py-1 text-sm font-semibold uppercase leading-4 tracking-wider text-yellow-800 lg:text-xs">
      Nuevo
    </div>
  );
}

export default function MenuQuicklinks() {
  return (
    <UnorderedList className="flex flex-col">
      <ListItem className="py-px">
        <Link
          href="/certificaciones"
          className="flex items-center space-x-2 rounded-full px-4 py-2 text-gray-600 hover:bg-white hover:text-gray-900 hover:shadow"
        >
          <svg
            className="h-6 w-6 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div className="font-medium">Certificaciones</div>
        </Link>
      </ListItem>
      <ListItem className="py-px">
        <Link
          href="/entrenamientos"
          className="flex items-center space-x-2 rounded-full px-4 py-2 text-gray-600 hover:bg-white hover:text-gray-900 hover:shadow"
        >
          <svg
            className="h-6 w-6 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
          </svg>
          <div className="font-medium">Cursos de entrenamiento</div>
        </Link>
      </ListItem>
      {/* <ListItem className="py-px">
        <Link
          href="/clases"
          className="flex items-center space-x-2 rounded-full px-4 py-2 text-gray-600 hover:bg-white hover:text-gray-900 hover:shadow"
        >
          <svg
            className="h-6 w-6 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
          <div className="font-medium">Calendario de cursos</div>
        </Link>
      </ListItem> */}
      <ListItem className="py-px">
        <Link
          href="https://socios.aibes.org/"
          className="flex items-center space-x-2 rounded-full px-4 py-2 text-gray-600 hover:bg-white hover:text-gray-900 hover:shadow"
        >
          <svg
            className="mb-1 h-6 w-6 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
          </svg>
          <div className="font-medium">Portal de socios</div>
          <MenuQuicklinksIsNew />
        </Link>
      </ListItem>
      <ListItem className="py-px">
        <Link
          href="/entrenadores"
          className="flex items-center space-x-2 rounded-full px-4 py-2 text-gray-600 hover:bg-white hover:text-gray-900 hover:shadow"
        >
          <svg
            className="h-6 w-6 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
          </svg>
          <div className="font-medium">Entrenadores</div>
        </Link>
      </ListItem>
    </UnorderedList>
  );
}
